.table-container {
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .search-box {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    padding: 12px;
    text-align: left;
  }
  
  .table thead {
    background-color: #f4f4f4;
  }
  
  .table th {
    cursor: pointer;
  }
  
  .sort-asc::after {
    content: "▲";
  }
  
  .sort-desc::after {
    content: "▼";
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  

  